<template>
	<div>
		<!--div class="alert alert-danger m-0 rounded-0 border-0 pt-3 pb-3 text-center col-md-12 fs-14" style="background: #ff5870; color: #fff;" v-show="BlackFriday">
			<span style=" padding-right: 4px; ">🎁 Get FLAT 40% OFF on all of our Annual Plans.</span> Coupon code <b>NEWYEAR2021</b>
			<i class="fa fa-close c-pointer" style="float: right; padding-top: 2px;" @click="BlackFriday = false"></i>
		</div-->

		
		
		<template v-if="currentPage_Check">
			<navigation-menu v-if="$route.name == 'home' || $route.name == 'pricing' "></navigation-menu>
			<!--  v-if="!$auth.check()" -->
			<router-view></router-view>
		</template>
		<div class="container-fluid" v-else>
			<template v-if="$auth.check() &&  $route.name != 'demo'">
				<div class="row"><!--  class="hbox stretch" -->
				<div style="display:none" v-if="!$store.state.WhiteLabel" >
					<div class="alert alert-danger m-0 rounded-0 border-0 pt-3 pb-3 text-center col-md-12 fs-14" v-if="emailVerified">
						<i class="fa fa-envelope pr-1"></i>{{ $t('CheckEmailLink') }}
						<a href="javascript:void(0)" @click="resendVerification()">{{ $t('RequestAnother') }}</a>
						<i class="fa fa-close c-pointer" style="float: right; padding-top: 2px;" @click="emailVerified = false"></i>
					</div>
				</div>
					
					<!--<template v-if="!$store.state.WhiteLabel">
						<div class="alert alert-info m-0 rounded-0 border-0 pt-3 pb-3 text-center col-md-12 fs-14" 
						v-if="TrialPeriod != null && TrialPeriod != '' && !emailVerified && TrialPeriod > 0 && plan_name != 'Free'">
							<span>
								{{ $t('TrialingAgencyPlan', { TrialPeriod: TrialPeriod }) }} 
								<router-link :to="{path: '/settings/plan', params: {page: 'plan'}}">upgrade now</router-link
								<span class="btn-link c-pointer" @click="$bvModal.show('modal-plans')">{{ $t('UpgradeNow') }}</span>
								{{ $t('DefaultMonthPrice') }}
							</span>
						</div>
					</template> -->
				</div>
				<div class="row">
					<div class="col-md-12 p-0">
						<div class="wrapper-content">
							
							<div :class="[$store.state.WhiteLabel ? '' : 'bg-dark-bule-2']"
							:style="{ 'box-shadow': $store.state.WhiteLabel ? '2px 0px 3px #eee' : 'none' }">
								<left-sidebar></left-sidebar>
							</div>
							
							<div class="main-content"><!--  :emailVerified="emailVerified" -->
								<navbar-section @checkEmailVerification="checkEmailVerification" @GetTrial="GetTrial"></navbar-section>
								<section class="wrapper mt-4">
									<router-view></router-view>
								</section>
							</div>
							<footer-section></footer-section>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<router-view></router-view>
			</template>
		</div>
		
		<notifications group="foo" position="bottom left" type="success">
			<template slot="body" slot-scope="props">
				<div :class="[props.item.type]">
					<!--i v-if="props.item.type == 'success'" :class="['icon-notify', 'fa fa-check-circle']"></i-->
					
					<svg v-if="props.item.type == 'success'" 
						style="transform: rotate(360deg);height: 31px;margin-top: 4px;margin-left: 2px;" viewBox="0 0 500 500">
						<path d="M170.718 216.482L141.6 245.6l93.6 93.6 208-208-29.118-29.118L235.2 279.918l-64.482-63.436zM422.4 256c0 91.518-74.883 166.4-166.4 166.4S89.6 347.518 89.6 256 164.482 89.6 256 89.6c15.6 0 31.2 2.082 45.764 6.241L334 63.6C310.082 53.2 284.082 48 256 48 141.6 48 48 141.6 48 256s93.6 208 208 208 208-93.6 208-208h-41.6z" fill="#15c371"/>
					</svg>
					
					<svg v-if="props.item.type == 'warn'" style="transform: rotate(360deg);height: 34px;margin-top: 2px;margin-left: 2px;"  viewBox="0 0 512 512">
						<path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192z" fill="none" stroke="#626262" stroke-miterlimit="10" stroke-width="32"/>
						<path d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 0 0-5.79-6h0a5.74 5.74 0 0 0-5.68 6z" fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 367.91a20 20 0 1 1 20-20a20 20 0 0 1-20 20z" fill="#626262"/>
					</svg>
					
					<span class="content-notify">
						<a class="title" v-if="props.item.title != ''">{{props.item.title}}</a>
						<span v-html="props.item.text"></span>
					</span>
					<div class="close-notify" @click="props.close">&times;</div>
				</div>
			</template>
		</notifications>
		
	</div>
</template>
<script>
	/* added */
	import leftSidebar 		from '@/components/Menu/leftSidebar.vue'
	import navbarSection	from '@/components/Menu/navbar.vue'
	import footerSection 	from '@/components/footerSection.vue'
	/* added */
	import navigationMenu 	from '@/components/Menu/Menu.vue'
	export default {
		name: 'app',
		
		components: {
			navigationMenu,
			leftSidebar,
			navbarSection,
			footerSection,
		},
		
		data() {
			return {
				//
				pagesets 	 : ['home', 'register', 'signup', 'login', 'pricing'],
				pagesetsTranslate 	 : ['register', 'signup', 'login','forgot','registertrial','agencydeal'],
				emailVerified: false,
				BlackFriday: true,
				isAndroid : false,
				TrialPeriod		: 0,
				plan_name		: '',
				
			}
		},
		methods: {

			
			notifyMe(message, variant) {
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			resendVerification () {
				
				let that = this
				that.notifyMe('The verification email have been successfully sent', 'success')
				that.emailVerified = false
				
				let url   = '/register/resendconfirmation'
				let method= 'POST'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},
			
			checkEmailVerification () {
				let that = this
				
				let url   = '/user'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					let feedback   = response.data
					that.plan_name = feedback.plan_name
					let user = feedback.user
					
					if ( feedback.user.email_verified_at == null ) {
						that.emailVerified = true
					} else {
						that.emailVerified = false
					}
					
					window.localStorage.setItem('oml-user', JSON.stringify(user) )
					that.$store.state.user	 	= user
					
					
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},
			
			// Trial
			GetTrial() {
				
				let that = this
				let url   = 'trial'
				let method= 'POST'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					let TrialPeriod  = response.data
					that.TrialPeriod = parseInt( TrialPeriod.trial )
					
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},

			getUserLang(){
				let that = this;
				let url   = '/user'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {

					let user = response.data.user
					var userJson  = JSON.parse( window.localStorage.getItem('oml-user') )
					userJson.lang = user.language
					localStorage.setItem('oml-user' , JSON.stringify( userJson ))
					localStorage.setItem('i18n_lang', user.language)
					window.localStorage.setItem('calendly_url', response.data.calendly_url )
					
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
				})
			}
			
		},
		computed: {
			currentPage_Check() {
				
				return this.pagesets.includes(this.$route.name)
			},
			
		},
		mounted() {
			let url   = '/permissions'
				let method= 'POST'
				let data  = {custom : true}
					
				this.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						
				
					let active = response.data.roles.custom.active
					let permissions = response.data.roles.custom.permissions
					this.$store.state.permissions = permissions
					this.$store.state.active = active
					if(active){
						if(permissions){
							let permissionsObject = Object.keys(permissions);
							document.getElementById('clients').remove()
							if(this.$route.name=='settings/clients'){
									window.location = "/"
							}
						
							//left menu list permissions
							
							if(!permissionsObject.includes('list sources')){
								
							
								document.getElementById('LeadSources').remove()
							}
							if(!permissionsObject.includes('list leads')){
								
								document.getElementById('MyLeads').remove()
							}
							if(!permissionsObject.includes('list destination')){
								
								
								document.getElementById('Destinations').remove()
								
							}

							if(!permissionsObject.includes('list users')){
								
								document.getElementById('team').remove()
							}
							if(!permissionsObject.includes('list status')){
								
								document.getElementById('leadstatus').remove()
							}
							if(!permissionsObject.includes('list users') && !permissionsObject.includes('list status')){
								document.getElementById('Settings').remove()
							}
							
							if(!permissionsObject.includes('analytics')){
								document.getElementById('Analytics').remove()
							}
							
							
						}
					}
					
						
				}).catch(( {response} ) => {
					console.log(response)
						
				})


				this.getUserLang()
			/*if(this.pagesetsTranslate.includes(this.$route.name)){
				if(navigator.language.indexOf("fr") !== -1){
					localStorage.setItem('i18n_lang' ,'fr' )
				}else{
					localStorage.setItem('i18n_lang' ,'en' )
				}
				this.$i18n.locale = localStorage.getItem('i18n_lang')
			} else{
				let url   = '/user'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					let feedback   = response.data
					let user = feedback.user
					if(user.language == 'en' || user.language != 'fr'){
						localStorage.setItem('i18n_lang' ,user.language )
					}else{
						if(navigator.language.indexOf("fr") !== -1){
							localStorage.setItem('i18n_lang','fr')
						}else{
							localStorage.setItem('i18n_lang','en')
						}
					}
					this.$i18n.locale = localStorage.getItem('i18n_lang')
				}).catch(( {response} ) => {
					console.log(response)
				})
			}*/
			
					
		},
		
		created: function () {
			this.getlogo()
				
				

			this.$http.interceptors.response.use(undefined, function (err) {
				return new Promise(function (/* resolve, reject */) {
					if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
						this.$store.dispatch('logout')
					}
					throw err;
				});
			});
		}
	}
</script>
<style lang="scss">
	@import '../../public/css/oml-icons.css';
	@import '../../public/css/oml-components.css';
	
	.form-placeholder-color {
		color: #737373;
	}
	
	.main-content {
		width: calc(100% - 200px);
		/* overflow: auto; */
	}
	
	.fs-14 {
		font-size: 14px !important;
	}
	
	.default-label {
		font-weight: 500;
		font-size: 14px;
	}
	
	.wrapper-content {
		display: flex;
		width: 100%;
		align-items: stretch;
		overflow: unset;
		min-height: 100vh;
	}
	body {
		background-color: #fff;
		/* overflow: hidden; */
	}
	
	.card-header {
		background-color: #fff;
	}
	
	.dropdown-icon {
		font-size:18px;
		cursor:pointer;
		padding: 6px 14px;
		color: #2d2d2d;
	}
	
	button.btn-login {
		cursor: pointer;
		width: 100%;
		height: 50px;
		line-height: 40px;
		background: #377dff;
		color: #fff;
		font-weight: 700;
		border: 0;
		border-radius: 4px;
		margin: 15px 0 30px
	}
	
	.help-block {
		color: #a94442;
		font-size: 14px;
	}
	
	.selector-for-some-widget {
		box-sizing: content-box;
	}
	/* Register */
	
	.u-clients {
		width: 70%;
		margin: 0 auto;
	}
	
	.mobile-testimonials .u-slick__pagination li span {
		background-color: #377dff;
	}
	
	.mobile-testimonials .u-slick__pagination li.slick-active span {
		border-color: #377dff;
	}
	
	.mobile-testimonials .u-slick__pagination:not(.u-slick__pagination--block) {
		justify-content: center !important;
	}
	
	@media (max-width: 992px) {
		.space-3,
		.space-bottom-3 {
			padding-bottom: 1rem !important;
		}
		.space-3,
		.space-top-3 {
			padding-top: 1rem !important;
		}
	}
	
	@media (min-width: 1400px) {
		.container {
			max-width: 1340px;
		}
	}
	
	@media (min-width: 1600px) {
		.container {
			max-width: 1540px;
		}
	}
	/* Register */
	
	.btn:focus, textarea:focus, input:focus, select:focus, .custom-control-label::before, .custom-control-label::after {
		/* border-color: #377dff !important; */
		outline: 0 !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.form-control:focus {
		border-color: #1fabec !important;
	}
	
	::-webkit-input-placeholder, :-moz-placeholder, ::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
		color: #888;
		font-size: 12px;
	}
	
	textarea:focus, textarea.form-control:focus, input.form-control:focus,
	input[type=text]:focus, input[type=password]:focus, input[type=email]:focus,
	input[type=number]:focus, [type=text].form-control:focus, [type=password].form-control:focus,
	[type=email].form-control:focus, [type=tel].form-control:focus,
	[contenteditable].form-control:focus {
		box-shadow: inset 0 -1px 0 #ddd;
	}
	
	a:hover{
		text-decoration:none !important;
	}
	
	.form-control{
		font-size: 12px;
		min-height: 38px;
	}
	
	.form-control-lg {
		height: 48px;
	}
	
	
	::-webkit-scrollbar {
		width: .5em;
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background-color: rgba(0,0,0,.1);
		border-radius: 1em;
	}
	
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #d8d8d8;
	}
	
	.modal-max {
		width: 100%;
		max-width: 100%;
		margin: 0px;
		height: 100vh;
		max-height: 100vh;
		min-height: 100vh;
		
		#modal-list-clients___BV_modal_content_ {
			height: 100vh !important;
			max-height: 100vh;
			min-height: 100vh !important;
		}
	}
	
</style>
